<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        สร้างใบขายปลีก : SA3-1
      </h3>
      <div class="card-toolbar">
        <!-- <button
          type="button"
          class="btn btn-sm btn-light btn-success btn-hover-scale me-2"
          style="font-size: 14px; font-weight: bold"
          @click="gotoImportExcel()"
        >
          นำข้อมูลเข้าจาก Excel
        </button> -->
        <button
          type="button"
          class="btn btn-sm btn-light btn-primary"
          style="font-size: 14px; font-weight: bold"
          @click="goToNew()"
        >
          <span class="svg-icon svg-icon-2x">
            <inline-svg src="media/Flatten.svg" /> </span
          >เพิ่มใบขายส่ง
        </button>
      </div>
    </div>
    <div class="card-title row ms-5 me-5 mt-6">
      <div class="col-sm-3 mb-3">
        <a-date-picker
          class="form-control ant-date"
          placeholder="ค้นหาจากวันที่"
          v-model:value="dateSearch"
          format="DD/MM/YYYY"
          inputReadOnly
        />
      </div>
      <div class="col-sm-3 mb-3">
        <input
          v-model="searchInput"
          placeholder="ค้นหาจาก เลขที่เอกสาร / รหัสผู้ซื้อ"
          type="text"
          class="form-control"
        />
      </div>
      <div class="col-sm-1 text-end">
        <button
          @click="search()"
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
        >
          ค้นหา
        </button>
      </div>
    </div>
    <div class="card-body" style="padding-top: 3px">
      <div class="table-responsive">
        <table
          class="table table-hover table-rounded table-striped border gy-7 gs-7"
        >
          <thead>
            <tr
              style="width: 100%"
              class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
            >
              <th>#</th>
              <th>เลขที่เอกสาร</th>
              <th>รหัสผู้ซื้อ</th>
              <th>ชื่อผู้ซื้อ</th>
              <th>วันที่สร้างเอกสาร</th>
              <!-- <th>ชื่อนิติบุคคล/บุคคลธรรมดา</th> -->
              <th>มูลค่า</th>
              <th>status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="11">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <td>{{ !showing1 ? index + 1 : showing1 + index }}</td>
              <td>
                <a style="font-weight: bold" @click="goToItemDetail(item)"
                  ><u>
                    {{ item.sales_no ? item.sales_no : "" }}
                  </u>
                </a>
              </td>
              <td>{{ item.customer_code ? item.customer_code : "" }}</td>
              <td>{{ item.customer ? item.customer.customer_name : "" }}</td>
              <td>{{ formatDate(item.createdAt.substring(0, 10)) }}</td>
              <!-- <td>{{ item.vendor ? item.vendor.vendor_name : "" }}</td> -->
              <td>
                {{ item.final_price ? item.final_price : "" }}
              </td>

              <td
                :style="
                  item.is_cancel === '1'
                    ? 'cursor: ; color: FireBrick ; font-weight: bold'
                    : item.status === '1'
                    ? 'cursor: ; color: green'
                    : item.status === '0'
                    ? 'cursor: ; color: blue'
                    : 'cursor: ; color: red'
                "
              >
                <!-- <td
                :style="
                  item.status === '1'
                    ? 'cursor: ; color: green'
                    : item.status === '0'
                    ? 'cursor: ; color: blue'
                    : 'cursor: ; color: red'
                "
              > -->
                {{
                  item.is_cancel === "1"
                    ? "ยกเลิกแล้ว"
                    : item.status === "1"
                    ? "อนุมัติ"
                    : item.status === "0"
                    ? "รออนุมัติการเพิ่ม"
                    : "ไม่อนุมัติ"
                }}
              </td>
              <td>
                <div class="row">
                  <div class="col-sm-6 d-flex justify-content-md-end">
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-success p-0"
                      @click="goToEdit(item.id)"
                    >
                      <i
                        :class="
                          item.status === '0'
                            ? 'bi bi-pencil-square'
                            : 'bi bi-eye-fill'
                        "
                      ></i>
                    </button>
                  </div>
                  <div class="col-sm-6">
                    <button
                      v-if="item.status !== '1'"
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-danger p-0"
                      @click="goToDelete(item, index)"
                    >
                      <i class="bi bi-trash3-fill"></i>
                    </button>
                    <div @mousedown="hoverExportBtn()">
                      <button
                        v-if="item.status === '1'"
                        type="button"
                        class="btn btn-link btn-color-muted btn-active-color-success m-0 p-0"
                        @click="goToExport(item, index)"
                      >
                        <div
                          :class="
                            !loadingExport
                              ? ''
                              : index == indexExport
                              ? 'spinner-grow spinner-grow-sm text-light'
                              : ''
                          "
                          role="status"
                        >
                          <i class="bi bi-printer-fill"></i>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <DialogConfirmByPass
      :dialogConfirmByPass="dialogConfirmByPass"
      :loadingUpdate="loadingUpdate"
      @submitConfirmPass="submitConfirmPass"
      @closeDialogConformByPass="closeDialogConformByPass"
    />

    <div v-if="isExport">
      <pdfFile :dataExport="dataExport" @generatedFile="generatedFile" />
    </div>

    <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import saleApi from "@/api/sale/";
import companyApi from "@/api/user/";

import DialogConfirmByPass from "../../components/dialogConfirmByPass.vue";
import Pagination from "../../components/pagination.vue";
import Loader from "../../components/loaderIndex.vue";
import moment from "moment";

import pdfFile from "../../components/files/salesFile.vue";

export default {
  components: {
    DialogConfirmByPass,
    Pagination,
    Loader,
    pdfFile,
  },
  setup() {
    document.title = "J&N | สร้างใบขายปลีก";
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    dataItemsShow: [],
    companyItems: [],

    showing1: null,

    dialogConfirmByPass: false,
    isExport: false,
    loadingExport: false,
    indexExport: "",

    tableItems: [],
    itemApprove: {},

    searchInput: "",
    dateSearch: "",
    allData: [],
  }),

  created() {
    this.getAll();
    this.getAllCompany();
  },

  methods: {
    async getAll(method) {
      this.loading = true;
      let responseData = [];
      // let companyId = localStorage.getItem("companyId");
      try {
        responseData = await saleApi.salesOrder.getAll("1");
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.dataItemsShow = responseData.data;
        this.tableItems = responseData.data;
        this.loading = false;
        if (method === 1) {
          await this.$router.push({
            query: {
              page: this.$route.query.page,
              get: 1,
            },
          });
        }
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    async getAllCompany() {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await companyApi.company.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.companyItems = responseData.data;
      }
    },
    setCompany(val) {
      if (val) {
        let findByval = this.companyItems.find((data) => data.id === val);
        if (findByval) {
          return findByval.company_name;
        }
      }
    },

    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end

    goToNew() {
      this.$router.push({
        path: "/sale/SA3-1/new",
        query: {},
      });
    },
    goToEdit(id) {
      this.$router.push({
        path: "/sale/SA3-1/edit",
        query: {
          id: id,
        },
      });
    },
    async goToDelete(item, index) {
      Swal.fire({
        icon: "question",
        title: `ต้องการลบ ${item.customer_name} หรือไม่ ?`,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.confirmDelete(item, index);
        }
      });
    },
    async confirmDelete(item, index) {
      let deleteResponse = [];
      deleteResponse = await saleApi.salesOrder.delete(item.id);
      if (deleteResponse.response_status === "SUCCESS") {
        Swal.fire({
          icon: "success",
          title: "ลบสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          this.dataItemsShow.splice(index, 1);
          this.tableItems.splice(index, 1);
          this.getAll(1);
        });
      }
    },
    async statusApprove(isApprove) {
      let updateResponse = [];
      const id = this.itemApprove.id;

      this.itemApprove = {
        ...this.itemApprove,
        status: isApprove ? 1 : 2,
      };
      updateResponse = await purchaseApi.purchaseOrder.update(
        id,
        this.itemApprove
      );
      if (updateResponse.response_status === "SUCCESS") {
        if (isApprove) {
          Swal.fire({
            icon: "success",
            title: `อนุมัติสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: `ไม่อนุมัติ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.loadingUpdate = false;
        this.getAll(1);
      } else {
        Swal.fire({
          icon: "warning",
          title: "อนุมัติไม่สำเร็จ !",
          showConfirmButton: false,
          timer: 1500,
        });
        this.loadingUpdate = false;
      }
    },
    handleApprove(item) {
      this.itemApprove = { ...item };
      this.dialogConfirmByPass = true;
    },
    async submitConfirmPass(isApprove) {
      this.statusApprove(isApprove);
      this.dialogConfirmByPass = false;
    },
    closeDialogConformByPass() {
      this.dialogConfirmByPass = false;
    },
    formatDate(date) {
      if (date) {
        const oldDate = moment(date).format("DD/MM/YYYY");

        return oldDate;
      } else {
        return "-";
      }
    },
    textSearch(val) {
      console.log({ val });
    },
    goToItemDetail(item) {
      this.$router.push({
        path: "/sale/SA3-1/edit",
        query: {
          id: item.id,
          status: item.status,
        },
      });
    },

    // Export file
    goToExport(item, index) {
      this.dataExport = item;
      this.indexExport = index;
      this.loadingExport = true;
      this.isExport = true;
    },
    generatedFile() {
      this.isExport = false;
    },
    hoverExportBtn() {
      this.loadingExport = false;
      this.isExport = false;
    },

    async search() {
      const newDate = this.dateSearch
        ? moment(this.dateSearch.$d).format("YYYY-MM-DD")
        : "";

      this.loading = true;

      const responseSearch = await saleApi.salesOrder.search(
        {
          search: this.searchInput,
          date: newDate,
        },
        "1"
      );

      if (responseSearch.response_status === "SUCCESS") {
        this.dataItemsShow = responseSearch.data;
        this.tableItems = responseSearch.data;
        this.allData = responseSearch.data;
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped src="@/assets/custom-outside.css"></style>
